<template>
  <q-card
    :class="notificacao.lida ? '!bg-white dark:!bg-d-neutral-20' : ''"
    class="flex flex-nowrap p-8 bg-neutral-20 !rounded-none !relative items-center gap-12 cursor-default justify-between border-b border-b-neutral-100/10"
    :lida="notificacao.lida"
    @mouseenter="
      () => {
        showButtonAction[`notifificao-${notificacao.id}`] = true
      }
    "
    @mouseleave="
      () => {
        showButtonAction[`notifificao-${notificacao.id}`] = false
      }
    "
  >
    <div class="flex !flex-nowrap gap-8 !items-center">
      <AvatarSingle
        size="40px"
        :src="notificacao.usuario_criacao_serializado.foto"
      />
      <div class="flex flex-col gap-4">
        <p class="text-paragraph-1 !font-semibold !text-sm text-primary-pure">
          {{ notificacao.usuario_criacao_serializado.nome }}
        </p>

        <div
          class="text-paragraph-1 !font-medium !text-sm text-neutral-60 max-w-[300px] flex flex-wrap"
        >
          {{ notificacao.mensagem }}
        </div>
        <div
          class="text-paragraph-3"
          :class="
            GLOBAL.calcularDiferenca(notificacao.data_criacao) === 'Agora'
              ? 'text-primary-pure'
              : 'text-neutral-50'
          "
        >
          {{ GLOBAL.calcularDiferenca(notificacao.data_criacao) }}
        </div>
      </div>
    </div>
    <hgroup
      :id="`btn_notificacao_${notificacao.id}`"
      class="absolute my-auto right-2"
    >
      <OButton v-show="showButtonAction[`notifificao-${notificacao.id}`]">
        <q-icon
          class="w-32 h-32 opacity-70 "
          name="svguse:#icon_btn_menu_notificacao"
        ></q-icon>
        <q-menu
          ref="refMenuNotification"
          :target="`#btn_notificacao_${notificacao.id}`"
          fit
          anchor="bottom left"
          self="top left"
          class="min-w-[200px]"
        >
          <q-list class="">
            <q-item class="p-0 m-0">
              <OButton
                class="w-full h-48"
                @click="() => marcarNotificaoComoLida(notificacao.id)"
              >
                <q-icon name="check" size="20px" class="primary-pure"></q-icon>
                Marcar como lida
              </OButton>
            </q-item>
            <q-separator />
            <q-item class="p-0 m-0">
              <OButton
                class="w-full h-48"
                @click="() => excluirNotificacao(notificacao.id)"
              >
                <q-icon name="delete" size="20px" color="alert-error"></q-icon>
                Excluir Notificação
              </OButton>
            </q-item>
          </q-list>
        </q-menu>
      </OButton>
    </hgroup>
  </q-card>
</template>

<script setup>
import { notificacaoService } from '../../services/notificacao.service'
import { ref } from 'vue'
import AvatarSingle from '../Avatar/AvatarSingle.vue'
import Emitter from '../../boot/Emitter'
import GLOBAL from 'js/utils/GLOBAL'
import OButton from '../Button/OButton.vue'

const showButtonAction = ref({})
const refMenuNotification = ref({})

const { patchNotificacao, deleteNotificacao } = notificacaoService()

const props = defineProps({
  notificacao: { type: Object, default: () => ({}) },
})

const marcarNotificaoComoLida = async (id) => {
  await patchNotificacao(id, { lida: true })
  Emitter.emit('set:notificacoes')
}
const excluirNotificacao = async (id) => {
  await deleteNotificacao(id)
  Emitter.emit('set:notificacoes')
}
</script>

<style lang="scss" scoped></style>
