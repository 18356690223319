<template>
  <ModalSide  :state="state" :open="state.open" :close="close" >
    <template #header>
      <header class="bg-neutral-10 px-24 flex flex-col gap-16">
        <div class="flex items-center w-full shrink-0 sm:px-16">
         <div class="mr-12 flex gap-16 flex-nowrap items-center">
           <div>
            <TextIcon
              class="py-24"
              :text="state.header.title"
              icon="svguse:#icon_three_points"
            />
          </div>

          <q-space></q-space>

          <OButton
            class="!p-0 place-items-center grid cursor-pointer rounded-full !w-40 !h-40"
            @click.stop.prevent="close"
          >
            <q-icon class="text-neutral-70" size="0.5rem" name="close"></q-icon>
          </OButton>
         </div>
          <OSelect
            v-model="produto"
            class="!max-w-[250px]"
            use-input
            :options="options.produtos"
            option-label="label"
            option-value="value"
            map-options
            emit-value
            multiple
            size="md"
            clearable
            label="Produto"
            @clear="produto = []"
          />
        </div>

        <OTabs v-model="tabs" :loading="false" :tabs="tabsList" secondary />
      </header>
    </template>

    <template #content>
      <section class="flex flex-col flex-1 h-full">
        <EmptyItem
          v-if="!tabs"
          text="Selecione um status para mostrar os resultados"
          class="my-auto"
          :icons="['svguse:#icon_empty_search']"
        />
        <q-tab-panels
          v-if="tabs"
          key="tabs"
          v-model="tabs"
          animated
          class="flex-1 h-full"
        >
          <!-- GANHOS -->
          <q-tab-panel name="ganhos">
            <div
              v-auto-animate
              class="flex flex-col gap-8 px-24 pt-24 table-side sm:px-24"
            >
              <OTableServerSideBase
                ref="refTableGanho"
                :api-url="url.ganho"
                :columns="columnsSide"
                :rows-per-page="10"
              >
                <template #body="props">
                  <q-tr class="cursor-pointer" :props="props">
                    <q-td
                      key="card"
                      :auto-width="false"
                      style="
                        max-width: 250px;
                        width: clamp(100px, 20vw, 250px);
                        padding: 0rem;
                        margin: 0rem;
                        margin-bottom: 8px;
                      "
                    >
                      <KanbanCard
                        type="especial"
                        class="w-full"
                        :card="props.row"
                        @click="$emit('click:openModal', props.row)"
                        @click:voltar="
                          () => onBackToBoard(props.row, 'arquivados')
                        "
                      />
                    </q-td>
                  </q-tr>
                </template>
              </OTableServerSideBase>
            </div>
          </q-tab-panel>

          <!-- PERDAS -->
          <q-tab-panel name="perdas">
            <div
              v-auto-animate
              class="flex flex-col gap-8 px-24 pt-24 table-side"
            >
              <OTableServerSideBase
                ref="refTablePerda"
                :api-url="url.perdido"
                :columns="columnsSide"
                :rows-per-page="10"
              >
                <template #body="props">
                  <q-tr class="cursor-pointer" :props="props">
                    <q-td
                      key="card"
                      :auto-width="false"
                      style="
                        max-width: 250px;
                        width: clamp(100px, 20vw, 250px);
                        padding: 0rem;
                        margin: 0rem;
                        margin-bottom: 8px;
                      "
                    >
                      <KanbanCard
                        type="especial"
                        class="w-full"
                        :card="props.row"
                        @click="$emit('click:openModal', props.row)"
                        @click:voltar="
                          () => onBackToBoard(props.row, 'arquivados')
                        "
                      />
                    </q-td>
                  </q-tr>
                </template>
              </OTableServerSideBase>
            </div>
          </q-tab-panel>

          <!-- ARQUIVADOS -->
          <q-tab-panel name="arquivados">
            <div
              v-auto-animate
              class="flex flex-col gap-8 px-24 pt-24 table-side"
            >
              <OTableServerSideBase
                ref="refTableArquivado"
                :api-url="url.arquivado"
                :columns="columnsSide"
                :rows-per-page="10"
              >
                <template #body="props">
                  <q-tr class="cursor-pointer" :props="props">
                    <q-td
                      key="card"
                      :auto-width="false"
                      style="
                        max-width: 250px;
                        width: clamp(100px, 20vw, 250px);
                        padding: 0rem;
                        margin: 0rem;
                        margin-bottom: 8px;
                      "
                    >
                      <KanbanCard
                        type="especial"
                        class="w-full"
                        :card="props.row"
                        @click="$emit('click:openModal', props.row)"
                        @click:voltar="
                          () => onBackToBoard(props.row, 'arquivados')
                        "
                      />
                    </q-td>
                  </q-tr>
                </template>
              </OTableServerSideBase>
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </section>
    </template>
  </ModalSide>
</template>

<script setup>
import {api} from 'modules/axios'
import { columnsSide } from '../../pages/columns'
import { computed, inject, ref, watch } from 'vue'
import { deepUnref } from 'vue-deepunref'
import { NotifyAlert } from 'js/vue/boot/Notify'
import EmptyItem from 'components/Empty/EmptyItem.vue'
import KanbanCard from 'components/Kanban/KanbanCard.vue'
import ModalSide from './ModalSide.vue'
import OButton from 'components/Button/OButton.vue'
import OSelect from 'components/Select/OSelect.vue'
import OTableServerSideBase from 'components/Table/OTableServerSideBase.vue'
import OTabs from 'components/Tabs/OTabs.vue'
import TextIcon from 'components/Text/TextIcon.vue'
const { modalKanbanSide } = inject('modal')

const produto = ref([])
const refTableGanho = ref(null)
const refTablePerda = ref(null)
const refTableArquivado = ref(null)

const { state, open, close, setState } = modalKanbanSide

const emit = defineEmits(['click:openModal', 'click:voltar'])
setState({ header: { title: 'Ganhos / Perdidos / Arquivados' } })
const url = computed(() => {
  return {
    arquivado: `${urlUseKanban.value.cards}&coluna_kanban=${
      especialCols.value.arquivado
    }&produto=${produto.value.join(',')}`,
    ganho: `${urlUseKanban.value.cards}&coluna_kanban=${
      especialCols.value.ganho
    }&produto=${produto.value.join(',')}`,
    perdido: `${urlUseKanban.value.cards}&coluna_kanban=${
      especialCols.value.perdido
    }&produto=${produto.value.join(',')}`,
  }
})
const searchModel = ref(null)

function fnSearch(value, tab) {
  if (refTableGanho.value && tab === 'ganhos')
    return refTableGanho.value?.pagination?.rowsNumber
  if (refTablePerda.value && tab === 'perdas')
    return refTablePerda.value?.pagination?.rowsNumber
  if (refTableArquivado.value && tab === 'arquivados')
    return refTableArquivado.value?.pagination?.rowsNumber
 
  return value
}

const tabs = ref('')

const loading = ref({
  ganhos: true,
  perdas: true,
  arquivados: true,
})

const cards = ref({
  ganhos: [],
  perdas: [],
  arquivados: [],
})

const especialCols = inject('especialCols')

function onBackToBoard(card, type) {
  const cardsFinal = deepUnref(cards)[type].filter((c) => c.id !== card.id)
  cards.value[type] = cardsFinal
  emit('click:voltar', card)
}
const tabsList = computed(() => {
  const list = []

  especialCols.value.ganho &&
    list.push({
      name: 'ganhos',
      label: 'Ganhos',
      length: fnSearch(cards.value.ganhos, 'ganhos'),
      loading: loading.value.ganhos || refTableGanho.value?.loading,
    })
  especialCols.value.perdido &&
    list.push({
      name: 'perdas',
      label: 'Perdidos',
      length: fnSearch(cards.value.perdas, 'perdas'),
      loading: loading.value.perdas || refTablePerda.value?.loading,
    })
  especialCols.value.arquivado &&
    list.push({
      name: 'arquivados',
      label: 'Arquivados',
      length: fnSearch(cards.value.arquivados, 'arquivados'),
      loading: loading.value.arquivados || refTableArquivado.value?.loading,
    })
  return list
})

watch(
  () => state.value.open,
  (v) => {
    tabs.value = 'arquivados'
    if (v) {
      searchModel.value = ''

       populateTabs(produto.value)
    }
  }
)

const { urls, data } = inject('context')

const options = ref({
  produtos: [
    {
      label: 'Pessoa Física',
      disable: true,
    },
     ...data.produtos_pf,
    {
      label: 'Pessoa Jurídica',
      disable: true,
    },
     ...data.produtos_pj,
  ],
})


const urlUseKanban = computed(() => {
  
    return urls.mesa_seguro
  }
)
async function getCards(colID, produto) {
  const url = `${urlUseKanban.value.cards}&coluna_kanban=${colID}&page=1&${produto}&limit=10`
  try {
    const { data } = await api.get(url)
    return data
  } catch (error) {
    NotifyAlert('Um erro aconteceu')
    console.log(error)
  }
}

watch(
  produto,
  () => {
    if (refTableGanho.value && tabs.value === 'ganhos')
      refTableGanho.value?.updatePagination({
        ...refTableGanho.value?.pagination,

        page: 1,
      })
    if (refTablePerda.value && tabs.value === 'perdas')
      refTablePerda.value?.updatePagination({
        ...refTablePerda.value?.pagination,

        page: 1,
      })
    if (refTableArquivado.value && tabs.value === 'arquivados')
      refTableArquivado.value?.updatePagination({
        ...refTableArquivado.value?.pagination,

        page: 1,
      })
    populateTabs(produto.value)
  },
  { deep: true }
)

async function populateTabs(produto) {
  loading.value.ganhos = true
  loading.value.perdas = true
  loading.value.arquivados = true
  if (especialCols.value.ganho) {
    const _ganho = await getCards(
      especialCols.value.ganho,
      `produto=${produto.join(',')}`
    )
    cards.value.ganhos = _ganho?.count
  }
  loading.value.ganhos = false

  if (especialCols.value.perdido) {
    const _perda = await getCards(
      especialCols.value.perdido,
      `produto=${produto.join(',')}`
    )
    cards.value.perdas = _perda?.count
  }
  loading.value.perdas = false
  if (especialCols.value.arquivado) {
    const _arquivado = await getCards(
      especialCols.value.arquivado,
      `produto=${produto.join(',')}`
    )

    cards.value.arquivados = _arquivado?.count
  }
  loading.value.arquivados = false
}
</script>

<style lang="sass">
.table-side
  .q-table
    border-spacing: .5rem
  .q-table__card .q-table__middle
    border: 0px !important
  .q-table thead tr
    height: 0px
  .q-table thead
    border-width: 0px
  tbody tr:not(:last-child) > td
    border-width: 0px
  .q-table thead, .q-table tr, .q-table th, .q-table td
    border-width: 0px

@media (max-width: 475px)
  .q-table__middle
    margin-left: 24px
</style>
