<template>
  <div class="grid grid-cols-3 gap-16 mt-16 sm:grid-cols-1">
    <fieldset class="flex-1 flex flex-col gap-4">
      <span class="text-neutral-60 text-paragraph-2">Empresa</span>
      <p class="text-paragraph-2 text-neutral-100">
        {{ data.nome_empresa_parceira || '-' }}
      </p>
    </fieldset>
    <q-separator class="-mx-24 col-span-full hidden sm:!flex"  />

    <fieldset class="flex-1 flex flex-col gap-4">
      <span class="text-neutral-60 text-paragraph-2">Volume</span>
      <p class="text-paragraph-2 text-neutral-100">
        {{ data.volume || '-' }}
      </p>
    </fieldset>
    <q-separator class="-mx-24 col-span-full"  />
  </div>

</template>

<script setup>
import { getEmpresasParceiras } from 'store/empresasParceiras.store.js'
import { onMounted, ref } from 'vue'

const emit = defineEmits(['patch:mesa:financeiro'])

const empresasParceiras = ref([])

const props = defineProps({
  data: { type: Object, default: () => ({}) },
  tipo: { type: String, default: '' },
})

onMounted(async () => {
  empresasParceiras.value = await getEmpresasParceiras()
})
</script>
<style lang="sass">
.my-class-select
  max-height: 300px
  height:  300px
  overflow-y: auto !important
</style>
