<template>
  <q-dialog
    transition-hide="slide-right"
    transition-show="slide-left"
    transition-duration="200"
    :model-value="state.open"
    class="ModalSide"
    @update:model-value="close"
  >
    <q-card
      class="q-dialog-card bg-white h-full w-[500px] md:!w-full md2:!max-h-[100vh] flex flex-col flex-nowrap overflow-hidden"
      :class="cardClass"
    >
      <!-- @slot Onde entra o Header, com default -->
      <div ref="headerRef">
        <slot name="header" :close="close" >
          <HeaderModalBase
            v-if="state.header.title"
            :title="state.header.title"
            @click:close="close"
          />
        </slot>
      </div>

      <!-- CONTENT -->
      <div
        class="q-dialog-content h-full flex flex-col flex-1 overflow-auto flex-nowrap"
      >
        <!-- @slot Onde entra o conteudo do modal -->
        <slot name="content" :data="state.data">
          <h2 class="text-title-2 flex-1 grid place-items-center">
            Conteúdo do modal
          </h2>
        </slot>
      </div>
      <!--  -->
    </q-card>
  </q-dialog>
</template>

<script setup>
import { computed, ref, useAttrs } from 'vue'
import HeaderModalBase from 'components/Header/HeaderModalBase.vue'
import OButton from 'components/Button/OButton.vue'

const attrs = useAttrs()
//
const props = defineProps({
  state: {
    type: Object,
    required: true,
  },
  open: {
    type: Function,
    required: true,
  },
  close: {
    type: Function,
    required: true,
  },
  cardClass: String,
})

const headerRef = ref(null)
// const headerHeight = computed(() => {
//   const h = headerRef?.value?.getBoundingClientRect().height
//   return '100px' || 'auto'
// })
const headerHeight = ref('100px')
</script>

<style lang="sass">
.ModalSide

  .q-dialog__inner--minimized > div
    max-height: initial
  .q-dialog__inner
    justify-content: flex-end
    inset: initial
    right:0 !important
    margin: initial
    padding: initial
    height: 100vh

  // .q-dialog-content



  // .base-modal
//   border: none

//   .q-dialog-card
//     height: auto
//     width: 100%
//     overflow: hidden
//     display: flex
//     flex-direction: column

//     .q-dialog-content
//       flex: 1
//       display: flex
//       flex-direction: column
//       max-height: calc(98vh - 5rem)
//       overflow-y: auto
//       height: 100%
//       padding: 1.5rem
</style>
