<template>
  <article class="flex flex-col px-24 py-16 flex-nowrap">
    <TextIcon
      class="text-title-4 mt-4"
      icon="sell"
      text="Tags"
      :icon-props="{ class: 'text-primary-pure/40' }"
    />
    <div class="flex flex-wrap gap-8 mb-6 mt-16">
      <ObadgeTag
        v-if="data.cross_sell"
        :color="returnRGB(data.tag_cross_sell.cor_letra)"
        :badgecolor="returnRGB(data.tag_cross_sell.cor_fundo)"
        :badge="true"
        square
      >
        <template #content>Cross Sell</template>
      </ObadgeTag>
      <ObadgeTag
        v-if="data.produto"
        :badgecolor="returnRGB(data.cores?.cor_fundo)"
        :color="returnRGB(data.cores?.cor_letra)"
        :badge="true"
        square
      >
        <template #content>{{ data.produto }}</template>
      </ObadgeTag>
      <ObadgeTag
        v-for="tag in data?.tags"
        :key="tag.id"
        :badgecolor="returnRGB(tag.cor_fundo)"
        :color="returnRGB(tag.cor_letra)"
        :badge="true"
        square
      >
        <template #content>{{ tag.nome }}</template>
      </ObadgeTag>
    </div>

    <div class="max-w-[300px]">
      <KanbanItemEditavelSelect
        ref="refTagPopup"
        type="tag"
        :options="tags"
        text="Selecione uma Tag"
        :select-props="{
          multiple: true,
          'use-chips': true,
        }"
        :selected="data.tags"
        :close-on-select="false"
        @updateValue="
          (v) => {
            edicaoObj = { tag: v?.map((i) => i?.id || i) || [] }
            emits('patch:mesa')
          }
        "
      >
        <template #selected-item="scope">
          <ObadgeTag
            :key="scope.opt.id"
            size="sm"
            :badge="true"
            :color="returnRGB(scope.opt.cor_letra)"
            :badgecolor="returnRGB(scope.opt.cor_fundo)"
            class="rounded-generic h-32 dark:!text-white/90"
            removable
            @remove="scope.toggleOption(scope.opt)"
          >
            <template #content>
              <p class="text-center mx-auto" square>
                {{ scope.opt.nome }}
              </p>
            </template>
          </ObadgeTag>
        </template>
      </KanbanItemEditavelSelect>
    </div>
    <q-separator class="-mx-24 mt-16" />
    <TextIcon
      class="text-title-4 mt-4"
      icon="description"
      text="Sobre o negócio"
      :icon-props="{ class: 'text-primary-pure/40' }"
    />

    <div class="flex gap-16 mt-16 sm:flex-col">
      <div class="flex flex-col flex-1 gap-4">
        <label for="">
          <span class="text-neutral-60 text-paragraph-2">Anexo do Cliente</span>
        </label>

        <div v-if="data.anexo" class="container-anexo">
          <div class="flex gap-0">
            <span class="one-line max-w-[160px]">{{ splitNomeAnexo(data.anexo) }}.</span
            ><span class="">{{ extensaoAnexo(data.anexo) }}</span>
          </div>

          <div class="flex gap-12">
            <a
              :href="data.anexo"
              :download="`${splitNomeAnexo(data.anexo)}.${extensaoAnexo(
                data.anexo
              )}`"
            >
              <OButton secondary size="sm" class="!shadow-sm btn-bg">
                <q-icon
                  size="1.25rem"
                  class="shrink-0 text-primary-pure"
                  name="svguse:#icon_download_anexo"
                ></q-icon>
              </OButton>
            </a>
            <OButton
              secondary
              size="sm"
              class="!shadow-sm btn-bg"
              :href="data.anexo"
              target="_blank"
            >
              <q-icon
                size="1.25rem"
                class="shrink-0 text-primary-pure"
                name="svguse:#icon_ver_anexo"
              ></q-icon>
            </OButton>
          </div>
        </div>
        <p v-else>-</p>
      </div>
      <KanbanModalFieldset label="Produto" :text="data.produto" />
    </div>

    <q-separator class="-mx-24 mt-16" />
    
    <KanbanModalResumoObservacao
    :data="data"
    :mesa="mesa"
    @patch:mesa:observacao="() => onEmit()"
    />
  </article>
</template>

<script setup>
import {  inject, onMounted, ref } from 'vue'
import { tagsService } from '../../services/tags.service'
import GLOBAL from 'js/utils/GLOBAL'
import KanbanItemEditavelSelect from 'components/Kanban/KanbanItemEditavelSelect'
import KanbanModalFieldset from './KanbanModalFieldset.vue'
import KanbanModalResumoObservacao from './KanbanModalResumoObservacao.vue'
import ObadgeTag from 'components/Badge/ObadgeTag.vue'
import OButton from 'components/Button/OButton.vue'
import TextIcon from 'components/Text/TextIcon.vue'

const { data, tipo } = defineProps({
  data: { type: Object, default: () => ({}) },
  tipo: { type: String, default: '' },
})

const edicaoObj = inject('edicaoObj')
const mesa = inject('mesa')
const tags = ref([])
const refTagPopup = ref(null)

const { returnRGB } = GLOBAL
const {getTagsTipoMesa} = tagsService()
const emits = defineEmits([
  'click:open',
  'click:open:vincular',
  'atualiza:modal',
  'patch:mesa',
])

const atualizaMesa = (clienteCodigo) => {
  if (clienteCodigo && clienteCodigo !== data.codigo_XP) {
    emits('patch:mesa')
  }
}


function onEmit() {
  emits('patch:mesa')
}

const splitNomeAnexo = (anexo) => {
  const ext = anexo.split('/').pop()
  const nome = ext.split('.')[0]
  return nome
}
const extensaoAnexo = (anexo) => {
  const ext = anexo.split('.').pop()
  return ext
}

onMounted(async () => {
  tags.value = await getTagsTipoMesa('mesa_seguro')
})
</script>

<style lang="sass" scoped>
.container-anexo
  width: 330px
  height: 56px
  padding: 12px
  display: flex
  justify-content: space-between
  align-items: center
  gap: 16px
  border-radius: 4px
  border: 1px solid  #F0F1F5
  background:  #FFF
.btn-bg
  border-radius: 3px !important
  background: rgba(0, 56, 99, 0.10) !important

@media (max-width:1500px)
  .wrapper-anexos
    grid-template-columns: repeat(3,1fr) !important
</style>
