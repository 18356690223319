<template>
  <q-dialog
    class="ModalKanban rounded-md"
    transition-hide="slide-down"
    transition-show="slide-up"
    transition-duration="200"
    :model-value="state.open"
    @update:model-value="close"
  >
    <q-card
      class="rounded-md overflow-hidden !max-h-[initial] md2:!min-w-[90%] sm:mt-10"
      :style="{
        minWidth: state.style.width,
        minHeight: state.style.height,
        maxWidth: '1180px',
      }"
    >
      <header class="bg-[#F5F5F5] px-24 pt-24 pb-0">
        <div class="flex h-60 flex-nowrap sm:flex-col sm:gap-16">
          <div class="flex gap-24 justify-between flex-nowrap">

            <div class="flex flex-col gap-4">
              <KanbanModalTitle
                :text="data?.nome_cliente || data?.nome_completo"
                :loading="isLoading"
              />
              <span class="text-neutral-70 px-32 text-paragraph-2"
                >ID: {{ data.id }}</span
              >
            </div>
            <OButton  size="md" class=" bg-primary-pure/10 text-primary-pure invisible sm:!visible" @click="close" >
              <q-icon name="clear" size="sm"></q-icon>
            </OButton>
          </div>

          <q-space class="sm:hidden" />
          <div
            v-show="data.qtd_tempo_total"
            class="flex flex-col gap-4 justify-end items-center sm:hidden"
          >
            <span class="text-paragraph-2 !font-medium">Tempo em aberto:</span>
            <span
              :class="color"
              class="text-paragraph-1 !font-semibold flex items-center gap-2"
            >
              <q-icon :class="color" size="20px" :name="icon"></q-icon>
              <span>{{ data.qtd_tempo_total }}</span>
            </span>
          </div>
         <div class="flex  sm:justify-between">
           <KanbanModalField
            text="Criação"
            :avatar="data.usuario_criacao_serializado?.foto"
            :date="FData(data?.data_criacao)"
            class="h-60 ml-32 sm:ml-0 self-end"
            :loading="isLoading"
          />
          <KanbanModalField
            text="Atualização"
            :date="FData(data?.data_atualizacao)"
            class="h-60 ml-28 sm:ml-0 self-end"
            :loading="isLoading"
            :avatar="data.usuario_atualizacao_serializado?.foto"
          />
         </div>

          <!-- <KanbanModalHeaderActions
           
            :data="data"
            :loading="isLoading"
          /> -->
        </div>
        <div class="flex mt-16 sm:mt-24 gap-24 sm:flex-col-reverse">
          <KanbanModalFunil
            v-if="!stayInSpecialCols"
            :loading="isLoading"
            :cols="cols"
            :active="data.coluna_kanban"
            class="flex-1"
          />
          <div class="flex gap-24 justify-between">
              <div
            v-show="data.qtd_tempo_total"
            class=" flex-col gap-4 justify-end items-center hidden sm:!flex"
          >
            <span class="text-paragraph-2 !font-medium">Tempo em aberto:</span>
            <span
              :class="color"
              class="text-paragraph-1 !font-semibold flex items-center gap-2"
            >
              <q-icon :class="color" size="20px" :name="icon"></q-icon>
              <span>{{ data.qtd_tempo_total }}</span>
            </span>
          </div>
            <KanbanModalTemperatura
            v-if="!stayInSpecialCols"
            :loading="isLoading"
            :temperatura="data.temperatura"
            class="ml-32 sm:ml-0"
          />

          </div>
        </div>
        

        <div class="grid grid-cols-2 gap-24 my-16 sm:grid-cols-1">
          <CardDadosCliente
            :data="data"
            :loading="isLoading"
            @patch:mesa="(v) => onSubmitEditTabs(v)"
          />
          <CardDadosAssessor
            :data="data.assessor_serializado"
            :loading="isLoading"
          />
        </div>

        <OTabs v-model="tabs" :loading="isLoading" :tabs="tabsArr" secondary />
      </header>

      <!-- TAB PANELS -->
      <q-form ref="formRef">
        <q-tab-panels
          v-if="!isLoading"
          key="tabs"
          v-model="tabs"
          animated
          class="flex-1 h-full min-h-[465px]"
        >
          <!-- RESUMO -->
          <q-tab-panel name="resumo">
            <section
              class="grid grid-cols-[1fr,450px] divide-x divide-neutral-40"
              :class="{ '!grid-cols-1': Screen.lt.lg }"
            >
              <div class="flex flex-col gap-16">
                <KanbanModalResumo
                  :data="data"
                  :tipo="context_data.mesa"
                  @click:open="onOpenModal"
                  @patch:mesa="(v) => onSubmitEditTabs(v)"
                  @atualiza:modal="(id) => atualizaDadosModal(id)"
                />
              </div>

              <KanbanModalAcompanhamento
                class="min-h-[466px]"
                :class="{ 'border-t border-t-neutral-40': Screen.lt.lg }"
                :data="data"
              />
            </section>
          </q-tab-panel>

          <!-- HISTORICO -->
          <q-tab-panel name="historico">
            <KanbanModalHistorico :data="data" :historico="historico" />
          </q-tab-panel>

          <!-- ANEXOS -->
          <q-tab-panel name="anexos">
            <div class="flex flex-col px-24 pt-32">
              <FileAnexo />
            </div>
          </q-tab-panel>

          <!-- ANEXOS -->
          <q-tab-panel name="outros">
            <div class="flex flex-col px-24 pt-0 pb-16">
              <KanbanModalTabOutros :data="data" />
            </div>
          </q-tab-panel>

          <q-tab-panel name="dados_financeiros">
           
            <div class="flex flex-col px-24 pt-0 pb-16">
              <kanbanModalTabFinanceiroSeguro
                
                :data="data"
                :tipo="context_data.mesa"
                @patch:mesa:financeiro="onSubmitEditTabs"
              />
             
              
            </div>
          </q-tab-panel>

          <!-- DADOS INVESTIDOR -->
          <q-tab-panel name="dados_investidor">
            <div class="flex flex-col px-24 pt-0 pb-16">
              <KanbanModalTabInvestidor :data="data" />
            </div>
          </q-tab-panel>

          <!-- DADOS CLIENTES -->
          <q-tab-panel name="dados_clientes">
            <div class="flex flex-col px-24 pt-0 pb-16">
              <KanbanModalTabClientes
                :data="data"
                @click:open="(id) => openModalClienteTipo(id)"
                @atualiza:modal="(id) => atualizaDadosModal(id)"
              />
            </div>
          </q-tab-panel>

          <!-- OBSERVACOES -->
          <q-tab-panel name="observacoes">
            <div class="w-full shrink-0 md:-mt-4 px-24 py-16 max-h-[420px]">
              <template v-if="!isLoadingAnotacoes">
                <div
                  class="flex flex-col w-full virtual-scroll flex-1 overflow-auto"
                >
                  <div v-if="anotacoes.length > 0" class="flex flex-col gap-16">
                    <ModalKanbanObservacaoItem
                      v-for="anotacao in anotacoes"
                      :key="anotacao.id"
                      :anotacao="anotacao"
                    />
                  </div>
                  <EmptyItem v-else class="mt-[40px]" />
                </div>
              </template>
              <template v-else>
                <q-inner-loading :showing="true">
                  <q-spinner size="30px" color="primary" />
                </q-inner-loading>
              </template>

              <div
                class="flex gap-12 items-center z-20 bg-white px-24 absolute bottom-16 left-0 right-0"
              >
                <fieldset class="flex gap-16 flex-1">
                  <OButton
                    icon="attach_file"
                    size="md"
                    secondary
                    class="!h-[2.75rem] !w-[2.75rem] !p-0 !border-neutral-40"
                    :loading="loading.anexo"
                    @click="submitAnexo"
                  />
                  <OInput
                    v-model="model.anotacao"
                    size="md"
                    class="flex-1"
                    placeholder="Escreva aqui..."
                    @keydown.enter.prevent="() => salvarAnotacao(state.id)"
                  />
                </fieldset>
                <OButton
                  secondary
                  size="md"
                  :loading="loading.salvar_anotacao"
                  @click="() => salvarAnotacao(state.id)"
                  >Salvar</OButton
                >
              </div>
            </div>
          </q-tab-panel>
          <q-tab-panel name="apolice">
            <div class="w-full px-24 py-16">
              <KanbanModalTabApolice :data="data" />
            </div>
          </q-tab-panel>
        </q-tab-panels>
        <div v-else class="flex gap-8 flex-nowrap px-24 pb-24">
          <q-skeleton width="70%" height="400px"></q-skeleton>
          <q-skeleton width="30%" height="400px"></q-skeleton>
        </div>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { anotacoesService } from '../../services/anotacoes.service'
import { computed, inject, onMounted, provide, ref, unref, watch } from 'vue'
import { date, Dialog, Screen } from 'quasar'
import { mesaSeguroService } from '../../services/mesa_seguro.service'
import { NotifyAlert, NotifyError, NotifySucess } from 'js/vue/boot/Notify'
import CardDadosAssessor from 'components/Card/CardDadosAssessor.vue'
import CardDadosCliente from 'components/Card/CardDadosCliente.vue'
import Emitter from 'js/vue/boot/Emitter'
import EmptyItem from 'components/Empty/EmptyItem.vue'
import FileAnexo from 'components/File/FileAnexo.vue'
import GLOBAL from 'js/utils/GLOBAL'
import KanbanModalAcompanhamento from 'components/Kanban/KanbanModalAcompanhamento.vue'
import KanbanModalField from 'components/Kanban/KanbanModalField.vue'
import KanbanModalFunil from 'components/Kanban/KanbanModalFunil.vue'
import KanbanModalHeaderActions from 'components/Kanban/KanbanModalHeaderActions.vue'
import KanbanModalHistorico from 'components/Kanban/KanbanModalHistorico.vue'
import KanbanModalResumo from 'components/Kanban/KanbanModalResumo.vue'
import KanbanModalTabApolice from 'components/Kanban/KanbanModalTabApolice.vue'
import kanbanModalTabFinanceiroSeguro from 'components/Kanban/kanbanModalTabFinanceiroSeguro.vue'
import KanbanModalTemperatura from 'components/Kanban/KanbanModalTemperatura.vue'
import KanbanModalTitle from 'components/Kanban/KanbanModalTitle.vue'
import ModalAnexo from './ModalAnexo.vue'
import ModalKanbanObservacaoItem from './ModalKanbanObservacaoItem.vue'
import OButton from 'components/Button/OButton.vue'
import OInput from 'components/Input/OInput.vue'
import OTabs from 'components/Tabs/OTabs.vue'

const props = defineProps({ cols: Array })
const model = ref({
  anotacao: '',
})
const { FData } = GLOBAL
const loading = ref({
  modal: true,
  btnEdicao: false,
  anotacoes: true,
  salvar_anotacao: false,
})
const {
  getAnotacoes,
  postAnotacao,
  postAnotacaoAnexo,
  isLoading: isLoadingAnotacoes,
} = anotacoesService()
const file = ref({})

const icon = ref('')
const verifyColumn = (dias) => {
  const numDias = Number(dias)
  if (numDias > 0 && numDias <= 29) {
    icon.value = 'check_circle'
    return 'text-[#18EC25]'
  } else if (numDias > 29 && numDias <= 44) {
    icon.value = 'report_problem'
    return 'text-[#D2CD00]'
  } else if (numDias > 44 && numDias <= 59) {
    icon.value = 'report'
    return 'text-[#F39C12]'
  } else if (numDias > 59) {
    icon.value = 'error_outline'
    return 'text-[#FF0000]'
  }
}

function submitAnexo() {
  Dialog.create({
    component: ModalAnexo,
    componentProps: {
      title: 'Anexar',
      file: file.value,
      persistent: false,
    },
  }).onOk(async (file) => {
    
     
      await postAnotacaoAnexo({indicacao_mesa:state.value.id, file, kanban_corporate:isCorporate?.value})
      anotacoes.value = await getAnotacoes(state.value.id)
   
  })
}

const tabs = ref('resumo')
const anotacoes = ref([])
const { modalKanban } = inject('modal')
const { state, open, close, setState } = modalKanban
const isCorporate = inject('isCorporate')
const { urls, data: context_data } = inject('context')
const especialCols = inject('especialCols')

const data = computed(() => {
  
  return state.value.data
})
const color = ref(' ')
const stayInSpecialCols = computed(() => {
  if (!especialCols?.value) return
  return Object.values(especialCols.value).filter(
    (id) => Number(data.value.coluna_kanban) === Number(id)
  )?.length
})


async function salvarAnotacao(idMesa) {
  try {
    if (!model.value.anotacao) {
      NotifyAlert('Digite um texto para salvar')
      return
    }
    await postAnotacao({
      texto: model.value.anotacao,
      kanban_corporate: isCorporate?.value,
      indicacao_mesa: idMesa,
    })
    anotacoes.value = await getAnotacoes(idMesa)
    model.value.anotacao = ''
  } catch (error) {
    console.log(error)
    NotifyError('Erro ao salvar Anotação')
  }
}


const { getDadosMesaSeguroId, isLoading } = mesaSeguroService()
const atualizaDadosModal = async (id) => {
  const responseMesaSeguroId = await getDadosMesaSeguroId(id)
  setState({ data: { ...responseMesaSeguroId } })
}
watch(
  () => state.value.open,
  async (v) => {
    if (v) {
      anotacoes.value = []
      await atualizaDadosModal(state.value.id)
       anotacoes.value = await getAnotacoes(state.value.id)
      if (state.value.data.qtd_tempo_total) {
        const tempoCard = state.value.data.qtd_tempo_total?.split(' ')
        if (tempoCard[1] === 'horas' || tempoCard[1] === 'minutos') {
          icon.value = 'check_circle'
          color.value = 'text-[#18EC25]'
        } else {
          color.value = verifyColumn(tempoCard[0])
        }
      }
    } else {
      setState({ data: {} })
     
    }
  }
)


const mesa = inject('mesa')

const isMesa = computed(() => {
  return {
    consorcio: mesa?.value?.produto === 'Consórcio',
    planoDeSaude: mesa?.value?.produto === 'Plano de Saúde',
    seguroDeVida: mesa?.value?.produto === 'Seguro de Vida',
  }
})
const tabsArr = ref([{ name: 'resumo', label: 'Resumo' }])

const { ModalkanbanAnexo: modalKanbanAnexo } = inject('modal')

function onOpenModal({ id }) {
  modalKanbanAnexo.setState({
    header: {
      title: 'Anexos',
    },
  })

  modalKanbanAnexo.open(id)
}

const edicaoObj = ref({})
const formRef = ref()

watch(
  () => tabs.value,
  () => {
    edicaoObj.value = {}
    objFinanceiro.value = {}
  
  },
  { deep: true }
)
const objFinanceiro = ref({})

const setTabsArr = () => {
  tabsArr.value = [{ name: 'resumo', label: 'Resumo' }]
  tabsArr.value.push(
    {
      name: 'dados_financeiros',
      label: 'Dados Financeiros',
    },
    // { name: 'apolice', label: 'Apólice' }
  )

  tabsArr.value.push({ name: 'historico', label: 'Histórico' })
  // tabsArr.value.push({ name: 'observacoes', label: 'Observações' })
  
}
onMounted(() => {
  setTabsArr()
  
})
Emitter.on('confirm:arquivar', (card, col) => {
  if (card.id === data.value.id) {
    state.value.data.coluna_kanban = col
  }
})

Emitter.on('confirm:perda', (card, col) => {
  if (card.id === data.value.id) {
    state.value.data.coluna_kanban = col
  }
})

Emitter.on('confirm:ganho', (card, col) => {
  if (card.id === data.value.id) {
    state.value.data.coluna_kanban = col
  }
})

Emitter.on('confirm:desarquivar', (card, col) => {
  if (card.id === data.value.id) {
    state.value.data.coluna_kanban = col
  }
})

provide('isMesa', isMesa)
provide('edicaoObj', edicaoObj)
provide('loading', loading.value)
provide('tabs', tabs)
</script>

<style lang="sass">
.ModalKanban  .q-dialog__inner
  overflow-y: auto   !important
</style>
