<template>
  <div class="flex items-center justify-between w-full gap-16 sm:ml-24">
    <OInput
      v-model="model.filter"
      size="md"
      debounce="300"
      placeholder="Pesquise por nome, tipo..."
      class="no-label p-0 w-[22rem] o-input"
      :class="props.showRowsPerPage ? '' : 'flex-1'"
      clearable
      @update:model-value="$emit('update', model)"
      @clear="$emit('update', model)">
      <template #append>
          <q-icon name="search" color="neutral-70" />
        </template>
    </OInput>

    <div v-if="props.showRowsPerPage" class="flex gap-8 items-center">
      <span
        class="text-paragraph-2 md:!text-paragraph-3 text-neutral-70 dark:text-white/70">
        Mostrar
      </span>
      <OSelect
        v-model="model.rowsPerPage"
        class="w-72 h-40"
        size="md"
        :options="[5, 10, 20, 30, 50]"
        @update:model-value="$emit('update', model)" />
      <span
        class="text-paragraph-2 md:!text-paragraph-3 text-neutral-70 dark:text-white/70">
        Linhas por página
      </span>
    </div>
  </div>
</template>

<script setup>
import { inject, ref, watch } from 'vue'
import OInput from 'components/Input/OInput.vue'
import OSelect from 'components/Select/OSelect.vue'

const emit = defineEmits(['update'])

const props = defineProps({
  filter: { type: String, default: '' },
  rowsPerPage: { type: Number, default: 10 },
  showRowsPerPage:{type: Boolean, default:true}
})

const pagination = inject('pagination')
const model = ref({
  filter: props.filter,
  rowsPerPage: pagination.value.rowsPerPage,
})

watch(
  () => pagination.value,
  (newValue) => {
   model.value.rowsPerPage = newValue.rowsPerPage
  }, {deep:true}
)

</script>

<style lang="sass"></style>
