import {api} from 'modules/axios'

const getEmpresasParceiras = async() => {
  try {
    const {data} = await api.get('api/empresa_parceira/')
    if(data){
      return  data?.map(i => ({'value': i.id, 'label': i.nome}))
    }
  } catch (error) {
    console.log(error)
  }
}

export {getEmpresasParceiras}
