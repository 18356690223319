<template>
  <q-menu
    ref="refMenu"
    class="h-max border-neutral-40 sm:!left-10"
    transition-show="jump-down"
    transition-hide="jump-up"
  >
    <q-list class="flex flex-col gap-12 !w-[360px] sm:!w-[320px]   px-12 py-16">
      <TextIcon
        text="Filtrar"
        icon="filter_list"
        class="text-neutral-70 text-paragraph-1 mb-16"
      />
      
        <OInput
          v-model="filtros.nome_cliente.model"
          clearable
          focus
          label="Cliente"
          size="md"
        />
        <OSelect
          v-model="filtros.codigo_assessor.model"
          class="w-full"
          use-input
          :options="options.codigo_assessor"
          option-label="label"
          option-value="value"
          size="md"
          clearable
          label="Assessor"
          @clear="filtros.codigo_assessor.model = ''"
        />
        <OSelect
          
          v-model="filtros.tag.model"
          class="w-full"
          use-input
          :options="options.tags"
          option-label="nome"
          option-value="id"
          map-options
          emit-value
          multiple
          size="md"
          clearable
          label="Tags"
          @clear="filtros.tag.model = null"
        />
        
     
    
      <q-item class="flex gap-8 justify-end h-max mt-24 p-0">
        <!-- :disable="true" -->
        <OButton size="md" tertiary class="flex-1" @click="onClickRemove">
          <div class="flex flex-nowrap gap-4 shrink-0 items-center">
            <q-icon name="close" />
            Remover Filtros
          </div>
        </OButton>

        <OButton primary size="md" class="flex-1" @click="onConfirm">
          <div class="flex flex-nowrap gap-4 shrink-0 items-center">
            <q-icon name="check" />
            Aplicar
          </div>
        </OButton>
      </q-item>
    </q-list>
  </q-menu>
</template>

<script setup>
import {api} from 'modules/axios'
import { computed, inject, onMounted, readonly, ref, watch } from 'vue'
import { tagsService } from 'js/vue/services/tags.service'
import OButton from 'components/Button/OButton.vue'
import OInput from 'components/Input/OInput.vue'
import OSelect from 'components/Select/OSelect.vue'
import TextIcon from 'components/Text/TextIcon.vue'
import useFilters from 'composables/useFilters'
const { data } = inject('context')

const refMenu = ref(null)
const { getTagsTipoMesa, getTags } = tagsService()
const options = ref({
  codigo_assessor: data.assessores,
  mesas: data.tipo_mesas,
  coluna: [],
  produtos: [],
  tags: [],
  tipo_pessoa: data.tipo_pessoa,
})



const getColunasKanban = async (coluna) => {
  try {
    const response = await api.get(
      `kanban/api/colunas_kanban/?coluna_de_${coluna}=${true}`
    )
    if (response?.data) {
      options.value.coluna = response?.data
    }
  } catch (error) {
    console.log(error)
  }
}

const filtrosDefault = readonly({
  codigo_assessor: { id: 1, name: 'Assessor', model: null, key: 'value' },
  nome_cliente: { id: 2, name: 'Cliente', model: '' },
  tipo_mesa: {
    id: 3, name: 'Tipo de mesa', model:
      ''
  },
  coluna: { id: 4, name: 'Coluna', model: '' },
  produto: { id: 5, name: 'Produto', model: null },
  tag: { id: 6, name: 'Tag', model: null },
})

const emit = defineEmits(['click:confirm', 'click:remove'])

const {
  filtros,
  qnt, //
  badges,
  query,
  setQnt,
  setBadges,
  isEmpty,
  onDateChange,
  onRemove,
  removeFilter,
  filtrosAplicados,
  setUrlParams,
  updateFilters,
} = useFilters(filtrosDefault)

const _query = ref('')

function onConfirm() {
  setQnt()
  setBadges()
  setUrlParams()
  _query.value = query.value
  emit('click:confirm', query.value)
}

function onClickRemove() {
  onRemove(filtrosDefault)
  _query.value = query.value
  emit('click:remove')
}

watch(
  () => filtros.value.tipo_mesa.model,
  async () => {
    await getColunasKanban(filtros.value.tipo_mesa.model)
  },
  { deep: true }
)
onMounted(async () => {
  setQnt()
  setBadges()
  
    options.value.tags = await getTagsTipoMesa('mesa_seguro')
  
  // await getColunasKanban()
})

defineExpose({
  filtros: filtrosAplicados,
  query: _query,
  qnt,
  badges,
  isEmpty,
  removeFilter,
  updateFilters,
})
</script>

<style lang="scss" scoped></style>
