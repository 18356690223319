<template>
  <q-select
    ref="componentRef"
    class="Oselect"
    :class="'size-' + attrs.size"
    popup-content-class="select-menu"
    input-debounce="300"
    transition-duration="150"
    v-bind="attrs"
    options-selected-class="option-selecionada"
    :size="null"
    :options="options"
    dropdown-icon="expand_more"
    :bottom-slots="true"
    behavior="menu"
    @filter="filterFn"
  >
    <!-- @focus="handleFocus" -->
    <template v-for="slot in Object.keys(slots)" #[slot]="slotProps">
      <slot :name="slot" v-bind="slotProps"></slot>
    </template>

    <template #no-option>
      <q-item>
        <div class="flex items-center gap-8">
          <q-icon
            class="block mx-auto opacity-30"
            name="fluorescent"
            size="2rem"
          ></q-icon>
          <p class="opacity-40">Sem Dados para exibir.</p>
        </div></q-item
      >
    </template>



    <!-- <template #before-options>
      <OInput
        ref="input"
        size="md"
        v-model="val"
        @keyup="handleInputChange"
        class="no-border !border-0"
      ></OInput>
    </template> -->
  </q-select>
</template>

<script>
export default { inheritAttrs: false }
</script>

<script setup>
import { nextTick, onMounted, ref, useAttrs, useSlots, watch } from 'vue'

const val = ref('')
const slots = useSlots()
const attrs = useAttrs()
const componentRef = ref(null)
const options = ref(attrs.options)

let stringOptions = attrs.options

// function handleFocus() {
//   componentRef.value.showPopup()
// }
// asas
watch(
  () => attrs.options,
  async (v) => {
    await nextTick()
    options.value = attrs.options
    stringOptions = attrs.options
  },
  { deep: true }
)

function filterFn(val, update, abort) {
  update(() => {
    if (!val) return (options.value = stringOptions)
    const needle = val?.toLowerCase()
    options.value = stringOptions.filter((v) => {
      const option = v[attrs['option-label']]
      const have = option?.toLowerCase().indexOf(needle) > -1
      return have
    })
  })
}

defineExpose({ componentRef })
</script>
