<template>
  <q-card v-if="!loading" class="p-8 overflow-hidden pb-0 sm:pb-8">
    <p class="text-caps-2 text-neutral-60 mb-6">Dados do assessor</p>
    <div class="grid grid-cols-2 md:grid-cols-1">
      <div class="flex items-center gap-6 flex-nowrap">
        <AvatarSingle :src="data.foto" />
        <div class="flex flex-col">
          <p class="text-neutral-100 text-paragraph-2 !font-medium">
            {{ data.nome || '-' }}
          
          </p>
          <p class="text-neutral-60 text-paragraph-2">
            Cod. {{ data.codigo || '-' }}
           
          </p>
        </div>
      </div>

      <div class="flex flex-col gap-6">
        <p
          class="flex items-center gap-4 text-neutral-100 cursor-pointer !font-medium"
          @click="copyAndAlert(data.telefone)"
        >
          <q-icon name="call" size="1.25rem" />
        {{ data.telefone || "-" }}

        </p>
        <p
          class="flex items-center gap-4 text-neutral-100 cursor-pointer !font-medium one-line"
          @click="copyAndAlert(data.email)"
        >
          <q-icon name="mail" size="1.25rem" />
          {{ data.email || "-" }}
        </p>

        <p
          class="flex items-center gap-4 text-neutral-100 cursor-pointer !font-medium"

        >
          <q-icon
            name="svguse:#icon_empresa"
            size="1.25rem"
            class="opacity-70"
          />
          {{ data.filial || data.nome_empresa || '-' }}
          
        </p>
      </div>
    </div>
  </q-card>
  <q-skeleton
    v-else
    v-once
    width="100%"
    height="140px"
    class="mb-16"
  ></q-skeleton>
</template>

<script setup>
import AvatarSingle from 'components/Avatar/AvatarSingle.vue'
import GLOBAL from 'utils/GLOBAL.js'

const { copyAndAlert } = GLOBAL

const props = defineProps({
  data: { type: Object, default: () => ({}) },
  loading: { type: Boolean, default: true },
})
</script>

<style lang="scss" scoped></style>
